.myOrders {
    max-width: 1440px;
    margin: 0 auto;
    .titleBar {
        font-size: 16px;
        font-weight: bold;
        padding: 0 0 10px;
    }
    .orderList {
        border-bottom: 1px solid #EFEFEF;
        padding-bottom: 20px;
    }
    .orderInfo {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        padding-top: 20px;

        .id {
            color: #9b9b9b;

            span {
                padding-left: 10px;
            }
        }

        .status {
            width: 120px;
            color: #9b9b9b;
            text-align: center;
        }
    }
    .OrderContent {
        display: flex;
        justify-content: space-between;
        .pdList {
            // width: 50%;
            justify-content: space-between;
            display: flex;
            padding: 10px 0;
        }

        .pdImg {
            width: 120px;
            height: 120px;
            background-color: #efefef;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .pdInfo {
            text-align: left;
            padding-left: 140px;
            position: absolute;
            font-size: 14px;

            .pdName {
                font-size: 14px;
                font-weight: bold;
            }

            .pdSubinfo {
                color: #757575;
            }

            .actionBox {
                margin-top: 10px;

                span {
                    cursor: pointer;
                    color: #1AA77D;
                    font-weight: 500;
                }

                div {
                    margin-top: -2px;
                }
            }
        }

        .payinfo {
            // width: 40%;
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            padding: 10px 0;

            span {
                display: block;
                font-size: 14px;
                color: #757575;
                font-weight: normal;
            }
        }

        .actions {
            width: 120px;
            cursor: pointer;
            padding: 10px 0;
            text-align: center;

            .actionInfo {
                border: 1px solid #dcdfe6;
                border-radius: 2px;
                padding: 6px 0 4px;
                font-size: 14px;
                width: 120px;
                font-weight: bold;
            }

            .actionMain {
                border: 1px solid #131415;
                background-color: #131415;
                color: #fff;
                border-radius: 2px;
                padding: 6px 0 4px;
                font-size: 14px;
                width: 120px;
                margin-bottom: 10px;
                font-weight: bold;
            }
        }



    }
    :global .ant-pagination{
        text-align: center;
        margin-top: 20px;
    }
    .noOrder{
        text-align: center;
        font-size: 14px;
        margin: 50px 0;
        color: #757575;
        span {
            padding-left: 5px;
        }
    }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;