@import '~antd/dist/antd.less';

// 引入iconfont
@import '../src/Assets/Iconfont/iconfont.css';

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

/*-------公共样式 --------*/
.grid-item {
  flex: 1;
  cursor: pointer;
  .masonry-img {
    width: 100%;
    object-fit: cover;
  }
  // }
}

// 页面容器
.Inf_page {
  width: 100%;
  height: 100vh;
  padding: 0 40px;
  @media screen and (max-width: 680px) {
    padding: 0 20px;
  }
}

// 提示
.ant-tooltip {
  font-size: 13px;
  line-height: 20px;
  .ant-tooltip-inner {
    min-width: auto;
    min-height: auto;
    padding: 6px 10px;
  }
}
.no-arrow {
  .ant-tooltip-arrow {
    display: none;
  }
}

// 按钮

// color 和 border 均为白色 背景色透明的按钮
.Inf_transparent_btn,
.Inf_transparent_btn:active,
.Inf_transparent_btn:focus,
.Inf_transparent_btn:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 135px;
  height: 40px;
  background-color: transparent;
}

// color 和 border 均为#EEEEEE 背景色FFFFFF的按钮
.Inf_default_btn,
.Inf_default_btn:active,
.Inf_default_btn:focus,
.Inf_default_btn:hover {
  border: 1px solid #eeeeee;
  color: #eeeeee;
  background-color: #ffffff;
  border-radius: 2px;
}

// border 为#4A4A4A 背景色#4A4A4A color #FFFFFF的按钮
.Inf_black_btn,
.Inf_black_btn:active,
.Inf_black_btn:focus,
.Inf_black_btn:hover {
  border: 1px solid #131415;
  color: #ffffff;
  background-color: #131415;
}

// 登录页面白色按钮
.Inf_white_btn,
.Inf_white_btn:active,
.Inf_white_btn:focus,
.Inf_white_btn:hover {
  width: 135px;
  height: 40px;
  background-color: #fff;
  border-color: #fff;
}

// 默认link按钮

.link-btn-default,
.link-btn-default:hover,
.link-btn-default:active,
.link-btn-default:focus,
.link-btn-default::after {
  color: #757575;
  padding: 0;
  border: none;
  background-color: transparent;
}

// 红色按钮
.dange-default-btn,
.dange-default-btn:hover,
.dange-default-btn:focus,
.dange-default-btn:active {
  color: @error-color;
  border-color: rgba(205, 41, 42, 0.2);
}

// 弹窗标题
.Inf_modal_title {
  .Inf_modal_title_text,
  .Inf_modal_subtitle_text {
    display: block;
    line-height: 26px;
  }

  .Inf_modal_title_text {
    //font-family: PingFangSC-Light;
    font-size: 18px;
    color: #2c2d30;
  }

  .Inf_modal_subtitle_text {
    //font-family: PingFangSC-Light;
    font-size: 12px;
    color: #757575;
    letter-spacing: 0;
  }
}

// 普通文本
.Inf_page_text {
  font-size: 12px;
  color: #9b9b9b;
}

// antd 样式覆盖
.sub-sider-menu {
  font-size: 14px;
  position: relative;
  max-height: calc(100% - 200px);
  overflow-y: auto;
  overflow-x: hidden;

  li.ant-menu-item {
    padding-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis; //超出部分以省略号显示
    white-space: nowrap;
  }

  .ant-menu-item-active a,
  .ant-menu-item-selected a:hover {
    color: #4a4a4a;
    font-weight: bolder;
  }

  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #4a4a4a;
    font-weight: bolder;
  }

  .sub-sider-menu-item a:hover,
  .ant-menu-item-selected.sub-sider-menu-item a:hover {
    color: @primary-color;
  }

  .ant-menu-item:hover {
    color: #4a4a4a;
    font-weight: bolder;
  }

  .ant-menu-item-selected {
    background-color: transparent;
  }
}

.ant-layout-footer{
  background: transparent;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  content: '*';
  display: inline-block;
  margin-left: 4px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 1;
  font-family: SimSun,sans-serif;
}
.ant-form-item.hiddenStar .ant-form-item-label > label.ant-form-item-required{
  &:after{
    display: none !important;
  }
}

// Popover 无箭头
.noArrow {
  .ant-popover-arrow {
    display: none;
  }
}

.ant-popover-inner {
  border-radius: 0;
  background-color: #fff; //界面颜色：分享弹框
}

// message

.message-success .ant-message-notice-content {
  border: 1px solid #c3e6cb;
  color: #2f732f;
  box-shadow: none;
  // background-color: #d4edda;
  background-color: #ffffff;
}

.message-warning .ant-message-notice-content {
  border: 1px solid #bea049;
  color: #965f10;
  box-shadow: none;
  // background-color: #fff3cd;
  background-color: #ffffff;
  // padding: 5px 10px;
}

.message-error .ant-message-notice-content {
  border: 1px solid #f5c6cb;
  color: #b0341e;
  box-shadow: none;
  // background-color: #f8d7da;
  background-color: #ffffff;
}

// 底部显示message

.bottom-message {
  .bottom-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    object-fit: cover;
  }
}

// antd  modal
// 去掉弹窗头部下边框
.ant-modal-header {
  border-bottom: none;
}

// 去掉弹窗尾部上边框
.ant-modal-footer {
  border-top: none;
}

.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  padding: 0 40px;
}

.ant-modal-header {
  padding-top: 36px;
  padding-bottom: 10px;
  background: transparent;
}

.ant-modal-body {
  margin: 20px 0;
}

.ant-modal-footer {
  padding-top: 10px;
  padding-bottom: 36px;
}

.ant-modal-content {
  background-color: #fff; //界面颜色：关于弹框
  .ant-modal-close {
    top: 20px;
    right: 20px;
    font-weight: normal;

    .ant-modal-close-icon {
      svg {
        display: none;
      }
    }

    .ant-modal-close-x:before {
      font-family: 'iconfont' !important;
      font-size: 30px;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e62c';
    }
    .ant-modal-close-x {
      content: '\e62c';
    }
  }
}
.ant-btn {
  font-weight: 500;
}

//分页样式覆盖
.ant-pagination-item-active,
.ant-pagination-item-active a,
.ant-pagination-item-active:hover,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:focus a {
  border-color: #757575;
  color: #fff;
  background-color: #757575;
}
.ant-pagination-item-active:hover a {
  color: #fff !important;
}
.ant-pagination-item:hover {
  border: 1px solid #757575;
  a {
    color: #4a4a4a;
  }
}
.ant-pagination-next:hover .ant-pagination-item-link:hover {
  color: #4a4a4a;
  border: 1px solid #757575;
}
.ant-pagination-prev:hover .ant-pagination-item-link:hover {
  color: #4a4a4a;
  border: 1px solid #757575;
}
.ant-pagination-disabled:hover .ant-pagination-item-link:hover {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
// 弹框取消样式
.footerCanceltext, .footerCanceltext:hover {
  color: #9b9b9b;
  border: none;
  box-shadow: none;
}
.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
  background:#131415;
  border-color: #131415;
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;