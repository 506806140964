.form{
  padding: 40px 0;
  text-align: center;
  // line-height: 30px;
  .name{
    font-size: 18px;
    font-weight: bold;
  }
  .remarkText {
    font-size: 13px;
    color: #757575;
    font-weight: 300;
    line-height: 18px;
  }
  .cont{
    text-align: center;
    height: 230px;
    .wx{
      height: 180px;
      width: 180px;
      border: 1px solid #e5e5e5;
      border-radius: 20px;
      margin: 40px auto 8px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 90px;
      color: #1AAD19;
    }
  }
  .email{
    height: 180px;
    max-width: 360px;
    padding: 0 30px;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    margin: 40px auto 100px;
    :global .ant-input {
      padding: 8px 0;
      border-bottom: 1px solid #e5e5e5 !important;
      border: 1px solid #fff;
    }
    :global .ant-btn {
      color: #fff;
      background: #4a4a4a;
      border-color: #4a4a4a;
    }
    button {
      margin-top: 0px;
    }
    .emailMark {
      padding: 8px 0;
      font-size: 30px;
      color: #066DD5;
    }
  }
  .btn{
    color: #066DD5;
    cursor: pointer;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;