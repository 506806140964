/*
 * @Author: melon 
 * @Date: 2020-08-19 19:08:49 
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-23 19:04:59
 */

// 布局容器
.Inf_layout_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Inf_layout {
  padding: 50px;
  height: 100vh;
  background-color: #fff;
  max-width: 1500px;
  min-width: 600px;
  @media (max-width: 680px){
    max-width: 100%;
    min-width: auto;
    padding: 20px;
    :global .ant-col{
      width: auto !important;
    }
  }
  .Inf_header {
    width: 100%;
    height: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: normal;
    //padding: 25px 0;

    // logo
    .Inf_header_logo {
      max-width: 160px;
      max-height: 80px;

      img {
        max-width: 180px;
        max-height: 60px;
        object-fit: scale-down;
      }
    }

    .Inf_menus_div {
      position: relative;
      width: 100%;
      margin: 50px 0 0 0;
      .Inf_menu_opt {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);

        .Inf_menu_opt_item {
          display: inline-block;
          // width: 16px;
          // height: 16px;
          font-size: 20px;
          color: #9b9b9b;
          margin-left: 18px;
        }
      }

      // 菜单
      .Inf_menus {
        text-align: center;
      }
    }
  }

  // 横向菜单去掉下边框
  :global .ant-menu-horizontal,
  :global .ant-menu-item {
    border-bottom: none;
  }

  :global .ant-layout-header {
    padding: 0 0px;
  }

  :global .ant-layout-footer {
    width: 100%;
    background-color: #fff;
  }

  // 横向菜单 选中/聚焦 时样式覆盖
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  :global .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
  :global .ant-menu-horizontal > .ant-menu-item-selected a,
  :global .ant-menu-horizontal > .ant-menu-item a:hover {
    border-bottom: none;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  }

  // 暗色主题 选中/聚焦 时样式覆盖

  :global .ant-menu-submenu-selected,
  :global .ant-menu-submenu-active {
    background-color: #fff;
    color: #000000;
    // :global .ant-menu-submenu-title {
    //   font-size: 14px;
    // }

    :global .ant-menu-item-only-child {
      font-size: 12px;
    }
  }

  :global .ant-menu.ant-menu-dark .ant-menu-item-selected,
  :global .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
  :global .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #fff;
    color: #000000;
    // font-size: 14px;
    font-weight: bold;
  }

  // 子菜单
  // :global .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  :global .ant-menu-submenu-active,
  :global .ant-menu-submenu-title:hover {
    background-color: #fff;
    color: #000000;
    // font-size: 14px;
    font-weight: bold;
  }

  :global .ant-menu-horizontal > .ant-menu-item,
  :global .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none;
  }

  .Inf_selected_menu {
    :global .ant-menu-submenu-title {
      background-color: #fff;
      color: #000000;
      // font-size: 14px;
      font-weight: bold;
    }
  }

  :global .ant-spin-container {
    display: flex;
    justify-content: center;
  }
  :global .ant-col{
    width: 25%;
  }
  // .project {
  //   .projectImgs {
  //   width: 100% !important;
  //   max-width: none !important;
  //   }
  // }
}

:global ul[id*='works'],
:global ul[id*='photoProject'] {
  :global .ant-menu-item a:hover,
  :global .ant-menu-item-selected a,
  :global .ant-menu-item-selected a:hover {
    background-color: #fff;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }
}

:global ul[id*='works'].ant-menu-vertical.ant-menu-sub,
:global ul[id*='photoProject'].ant-menu-vertical.ant-menu-sub {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

:global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
  color: #000000;
}

// 滑出菜单的边距
:global .ant-menu-vertical.ant-menu-sub {
  padding: 12px 0;
  min-width: 128px;
}

:global .ant-menu-vertical {
  background-color: blue;
  :global .ant-menu-item,
  .subMenuLi {
    font-size: 12px;
    color: #000000;
    // 控制调整菜单的行高
    line-height: 24px;
    height: 24px;
  }

  :global .ant-menu-item:hover,
  :global .ant-menu-item-active {
    font-size: 12px;
    color: #000000;
  }

  :global .ant-menu-item > a:hover,
  .ant-menu-item-selected a {
    font-size: 12px;
    color: #000000;
    font-weight: bolder;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;