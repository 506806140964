.form_wrapper {
  margin: 0 0 0 20px;
  width: 500px;
  //padding: 0 24px;
  // border: 1px solid #E1E1E1;
  @media (max-width: 768px) {
    margin: 0;
    width:100%;
    .form{
      .title{
        text-align: center;
      }
    }
  }
  :global .ant-select-arrow {
    right: 8px;
    :global .iconfont {
      font-size: 12px;
    }
  }
  .form {
    //width: 360px;

    .title {
      font-size: 20px;
      color: #4A4A4A;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  :global .ant-form-item-label>label.ant-form-item-required::after {
    content: '*';
    display: inline-block;
    //margin-right: 4px;
    font-size: 14px;
    line-height: 1;
  }

  :global .ant-form-item {
    //margin: 12px;
    // width: 500px;
  }

  :global .ant-form-vertical .ant-form-item-label,
  :global .ant-col-24.ant-form-item-label,
  :global .ant-col-xl-24.ant-form-item-label {
    padding: 0;
    height: 35px;
  }

  .submit_btn,
  .submit_btn:hover,
  .submit_btn:active {
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.85);
    //border-color: rgba(0, 0, 0, 0.85);
    border-radius: 2px;
    width: 100%;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.85);
    color: #fff;
  }

  :global .ant-select-arrow {
    right: 20px;
  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;