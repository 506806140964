.aboutBody {
  text-align: center;
  .logo {
    max-height: 80px;
    max-width: 180px;
  }
  .wxCode {
    width: 200px;
    height: 200px;
    object-fit: contain;
    // border: 1px solid #E1E1E1;
    padding: 10px;
    margin-top: 20px;
  }
  .wxDec {
    font-size: 12px;
    color: #9b9b9b;
  }
  .userInfo {
    border: 1px solid #eeeeee;
    border-radius: 2px;
    display: inline-block;
    min-width: 300px;
    max-width: 100%;
    padding: 10px 20px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 20px;
    .accName {
      font-weight: 500;
      line-height: 18px;
    }
    .account {
      font-size: 13px;
      line-height: 18px;
    }
  }
  .bandname {
    font-size: 14px;
    font-weight: bold;
    padding: 20px 0;
  }
  :global p {
    margin-bottom: 0em;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;