.Inf_packDownload_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .list_title {
    font-size: 16px;
    color: #2C2D30;
    line-height: 18px;
    margin-bottom: 50px;
  }

  .name {
    display: inline-block;
    margin-right: 5px;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }

  :global .Inf_page_text {
    font-size: 14px;
  }

  :global .ant-list {
    width: 100%;
  }

  :global .ant-checkbox {

    // checkbox 样式
    :global .ant-checkbox-inner {
      border-radius: 50%;
      border-color: #CFD1D7;
    }

  }

  :global .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #757575;
    border-color: #757575;
  }

  :global .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #757575;
  }

  :global .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #f0f0f0;
  }

  :global .ant-btn-lg {
    width: 100%;
    height: 50px;
  }
 
}

.download_text {
  margin-top: 100px;
  font-size: 16px;
  color: #2C2D30;
  text-align: center;
  line-height: 18px;
  // font-weight: 300;
  margin-bottom: 50px;
}

.download_button_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  
  :global .ant-btn:hover {
    border: 1px solid #4A4A4A;
    color: #4A4A4A;
  }
  :global .ant-btn:focus {
    border: 1px solid #4A4A4A;
    color: #4A4A4A;
  }

  .checked {
    :global .ant-btn {
      border: 1px solid #4A4A4A;
      color: #4A4A4A;
    }
  }
}

.icon_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;