.service_wrapper {
  padding: 30px 0 20px;

  :global .ant-row {
    justify-content: center;
    @media (max-width: 680px){
      display: block;
      :global .ant-col {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .service_title {
    // margin-bottom: 20px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    font-weight: bold;
  }

}

.service_card_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px;
  @media (max-width: 680px){
    margin: 20px 12px 30px;
  }
  .service_item_image {
    height: 200px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .service_item_title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    margin: 5px 0 0 0;
  }

  .service_item_des {
    font-size: 12px;
    color: #9B9B9B;
    text-align: center;
    line-height: 18px;
  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;