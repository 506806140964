.myProjects {
    max-width: 1440px;
    margin: 0 auto;
    .titleBar {
        font-size: 16px;
        font-weight: bold;
        padding: 0 0 10px;
    }
    .projectList {
        border-bottom: 1px solid #EFEFEF;
        padding-bottom: 20px;
    }
    .projectContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pdList {
            justify-content: space-between;
            align-items: center;
            display: flex;
            padding: 10px 0;
        }
    
        .pdImg {
            width: 120px;
            height: 120px;
            background-color: #efefef;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .pdInfo {
            text-align: left;
            padding-left: 140px;
            position: absolute;
            font-size: 14px;
    
            .pdName {
                font-size: 14px;
                font-weight: bold;
            }
    
            .pdSubinfo {
                color: #757575;
            }

            .acDelete {
                font-size: 12px;
                // color: #1AA77D;
                padding-top: 10px;
                text-decoration: underline;
                cursor: pointer;
            }
    
            .actionBox {
                margin-top: 10px;
    
                span {
                    cursor: pointer;
                    color: #1AA77D;
                    font-weight: 500;
                }
    
                div {
                    margin-top: -2px;
                }
            }
        }
    
        .payinfo {
            text-align: left;
            font-size: 14px;
            padding: 10px 0;
        }
    
        .actions {
            width: 120px;
            cursor: pointer;
            padding: 10px 0;
            text-align: center;

            .actionInfo {
                border: 1px solid #dcdfe6;
                border-radius: 2px;
                padding: 6px 0 4px;
                font-size: 14px;
                width: 120px;
                font-weight: bold;
            }
            
            .actionMain {
                border: 1px solid #131415;
                background-color: #131415;
                color: #fff;
                border-radius: 2px;
                padding: 6px 0 4px;
                font-size: 14px;
                width: 120px;
                margin-bottom: 10px;
                font-weight: bold;
            }
        }
        
        
    
    }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;