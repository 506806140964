.Inf_packDownload_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .list_title {
    font-size: 16px;
    color: #2C2D30;
    line-height: 18px;
    margin-bottom: 50px;
  }

  :global .Inf_black_btn {
    margin-top: 50px;
    height: 40px;
    width: 100px;
  }

  :global .ant-input-affix-wrapper-disabled {
    background-color: transparent;

  }

  :global .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    //font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 0
  }

  .inf_packDownLoad {
    min-width: 500px;
    max-width: 800px;
  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;