.orderDetails {
    max-width: 1440px;
    margin: 0 auto;
    .titleBar {
        font-size: 16px;
        font-weight: bold;
        padding: 0 0 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;
        .forBack{
            line-height: 32px;
            div {
                display: inline-block;
                cursor: pointer;
                span {
                    margin-right: 6px;
                    font-size: 14px;
                }
            }
        }
        .actions {
            cursor: pointer;
            text-align: center;
            display: flex;

            .actionNormal {
                border: 1px solid #dcdfe6;
                border-radius: 2px;
                padding: 6px 20px 4px;
                font-size: 14px;
                font-weight: bold;
                margin: 0 5px;
            }

            .actionMain {
                border: 1px solid #131415;
                background-color: #131415;
                color: #fff;
                border-radius: 2px;
                padding: 6px 20px 4px;
                font-size: 14px;
                font-weight: bold;
                margin-left: 5px;
            }
        }
    }

    .blockTitle {
        padding: 30px 0 10px;
        text-align: left;
        font-weight: bold;
    }

    .orderSummary {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

    }

    .summaryBlock {
        width: 50%;
        text-align: left;
        font-size: 12px;
        font-weight: bold;
        color: #9b9b9b;
        line-height: 40px;
        margin-bottom: 10px;

        span {
            display: block;
            font-size: 14px;
            line-height: 24px;
            color: #4A4A4A;
            font-weight: bold;
        }
    }

    .orderInfo {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        font-size: 12px; //标题栏样式
        line-height: 26px;

        .id {
            color: #9b9b9b;
            text-align: left;

            span {
                padding-left: 10px;
            }
        }

        .status {
            width: 100px;
        }
    }

    .flexContent {
        display: flex;
        overflow-y: auto;
        border-bottom: 1px solid #efefef;
        padding: 20px 0;

        .orderList {
            width: 50%;
            justify-content: space-between;
            display: flex;
        }

        .pdImg {
            width: 120px;
            height: 120px;
            background-color: #efefef;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .pdInfo {
            text-align: left;
            padding-left: 140px;
            position: absolute;
            font-size: 14px;

            .pdName {
                // font-size: 16px;
                font-weight: bold;
            }

            .pdSubinfo {
                color: #757575;
            }

        }

        .payinfo {
            width: 30%;
            text-align: left;
            font-size: 14px;

        }

        .actionInfo {
            display: flex;
            justify-content: right;
            width: 20%;
            font-weight: bold;
            font-size: 14px;

        }
    }

    .infoBlock {
        display: flex;
        justify-content: right;

        .priceSumm {
            padding: 20px 0;
            width: 300px;
            text-align: right;

        }

        .subPrice {
            font-size: 14px;
            line-height: 28px;
            display: flex;
            justify-content: space-between;

        }

        .totalPrice {
            font-size: 16px;
            font-weight: bold;
            line-height: 50px;
            display: flex;
            justify-content: space-between;
        }

        .priceTitle {
            width: 100px;
        }

        .price {
            width: 200px;
        }

    }

}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;