.header {
  position: fixed;
  top: 0;
  z-index: 99;
  height: 300px;
  right: 44px;
  left: 44px;
}
.waterfullBody {
  padding-top: 10px;
  .imgItem {
    width: 340px;
    :global img {
      width: 100%;
    }
  }
}

:global .fixAntdAndMosonryGrid{
  @media (min-width: 1200px){
    .ant-col-xl-6{
      width:25%;
    }
  }
  @media (min-width: 992px){
    .ant-col-xl-6{
      width:25%;
    }
  }
  @media (min-width: 768px){
    .ant-col-md-8{
      width:33.333%;
    }
  }
  @media (min-width: 576px){
    .ant-col-sm-12{
      width:50%;
    }
  }
  .ant-col-xs-24{
    width:100%;
  }
}

// .imgTop,
// .imgBottom {
//   position: absolute;
//   z-index: 21;

//   // display:;
// }
// .imgTop {
//   top: 12px;
//   left: 12px;
//   right: 12px;
//   height: 32px;
//   display: flex;
//   justify-content: space-between;
//   .imgTopLeft {
//     .imgIcon {
//       margin-right: 10px;
//     }
//   }
//   .imgTopRight {
//     .imgIcon {
//       margin-left: 10px;
//       color: rgba(0, 0, 0, 0.4);
//     }
//   }
// }

// .imgBottom {
//   height: 50px;
//   bottom: 3px;
//   left: 5px;
//   right: 5px;
//   padding: 0 12px;
//   background-image: linear-gradient(
//     180deg,
//     rgba(0, 0, 0, 0) 0%,
//     rgba(0, 0, 0, 0.6) 100%
//   );
//   .imgBtns {
//     height: 100%;
//     text-align: right;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     .imgIcon {
//       font-size: 16px;
//       margin-left: 16px;
//       color: rgba(0, 0, 0, 0.4);
//     }
//   }
// }

// .imgIcon {
//   width: 20px;
//   height: 20px;
// }

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;