.packDownloadPage {

  width: 60%;
  min-width: 600px;

  @media (max-width: 768px) {
    width:100%;
    min-width: auto;
  }

  .header {
    // background-color: #ffffff;


    .topInfo {
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 680px) {
        height: 240px;
      }
      .projectName {
        font-size: 30px;
        //margin-top: 24px;
        // font-weight: bold;
        //color: #000000;
        text-align: center;
        line-height: 32px;
        @media screen and (max-width: 680px) {
          font-size: 24px;
          line-height: 28px;
        }
      }

      .projectDate {
        font-size: 14px;
        //color: #4a4a4a;
        text-align: center;
        font-family: 'Times New Roman';
        font-style: italic;
        margin-top: 10px;
      }

      .logo {
        margin-top: 30px;
        text-align: center;
        // width: 80px;
        // height: 80px;
        :global img {
          width: 80px;
          height: 80px;
          object-fit: scale-down;
          background-color: #ffffff;
          border-radius: 8px;
          //padding: 3px;
        }
      }
    }

  }

  .list,
  .form {
    width: 100%;
    margin-top: 50px;
  }

  .name {
    display: inline-block;
    margin-right: 5px;
    font-weight: bold;
    font-size: 14px;
    //color: #000000;
  }


}

.process_modal {
  :global .ant-modal-body {
    height: 100px;
    font-size: 16px;
    color: #2C2D30;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global .ant-modal-footer {
    padding: 0;
  }
}

.email_modal {
  .down_title {
    font-size: 16px;
    color: #2C2D30;
    line-height: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .down_desc {
    font-size: 12px;
    color: #757575;
    letter-spacing: 0;
    font-weight: 300;
    margin-bottom: 36px;
  }
  .input_label {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 0;
    font-weight: 500;
  }
  :global .ant-modal-body {
    padding: 36px 40px;
  }

  :global .Inf_black_btn {
    width: 110px;
    height: 40px;
  }

  :global .ant-modal-footer {
    padding: 0;
  }
  .submit_btns {
    display: flex;
    width: 100%;
    margin-top: 47px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.success_modal {
  :global .ant-modal-body {
    // height: 305px;
    padding: 56px 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .success_text {
    font-size: 16px;
    color: #2C2D30;
    text-align: center;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0 30px;
  }

  :global .ant-btn {
    margin-top: 47px;
    border: 1px solid #4A4A4A;
    border-radius: 2px;
    // width: 110px;
    height: 40px;
    padding: 10px 22px 10px;
    margin-bottom: 13px;
  }

  :global .ant-btn:focus {
    color: #4A4A4A;;
  }

  :global .ant-btn:hover {
    color: #4A4A4A;;
  }

  .down_alarm {
    font-size: 12px;
    color: #9B9B9B;
    text-align: center;
    line-height: 18px;
    font-weight: 400;
  }

  :global .ant-modal-footer {
    padding: 0;

  }
}
@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;