/*
 * @Author: melon 
 * @Date: 2020-08-20 15:09:34 
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-20 20:14:34
 */
 
// 布局容器
.Inf_layout_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Inf_layout {
  // margin-top: 50px;
  // padding-right: 50px;
  background-color: #fff;
  max-width: 100%;
  min-width: 500px;
  height: 100vh;
  @media (max-width: 680px){
    min-width: auto;
    .Inf_sider{
      width: auto !important;
      min-width: auto !important;
    }
  }

  :global .ant-layout-content {
    padding-right: 60px !important;
    padding-top: 60px !important;
  }

  // 侧边栏
  .Inf_sider {
    background-color: #fff;
    //text-align: center;
    padding-top: 60px;
    padding-left: 60px;
    width: 300px !important; //带有important的都是表示覆盖element.style的页面样式，高元@210423
    min-width: 300px !important;
    max-width: 300px !important;
    //flex: 0 0 300px !important;

    // logo
    .Inf_header_logo {
      // max-width: 160px;
      // max-height: 80px;

      img {
        max-width: 180px;
        max-height: 60px;
        object-fit: scale-down;
      }
    }

    .Inf_menu_opt {
      display: flex;
      //padding: 0 24px;
      //justify-content: center;
      .Inf_menu_opt_item {
        display: inline-block;
        // width: 16px;
        // height: 16px;
        font-size: 20px;
        color: #9b9b9b;
        margin-right: 16px;
      }
    }

    .Inf_menus_div {
      padding: 28px 0 20px 0;

      // 菜单
      .Inf_menus {
        // 默认一级菜单样式
        max-height: calc(100vh - 230px);
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 14px;
        :global .ant-menu-item {
          line-height: 30px;
          height: 30px;
          //margin-bottom: 0px;
        }
        // 展开二级菜单样式
        :global ul {
          font-size: 12px;
          margin-bottom: 8px;
          margin-left: 8px; //二级菜单缩进
          :global .ant-menu-item {
            line-height: 16px;
            height: 25px;
            margin-bottom: 0px;
          }
          :global .ant-menu-item a, 
          :global .ant-menu-item a:hover,
          :global .ant-menu-item-selected a, 
          :global .ant-menu-item-selected a:hover {
            font-size: 12px;
          }
        }
        :global .ant-menu-item {
          padding: 0px;
          padding-left: 0px !important;
        }
        :global .ant-menu-submenu-title {
          padding-left: 0px !important;
          padding-right: 0px;
          height: 30px;
          line-height: 30px;
        }
        :global .ant-menu-item:hover,
        :global .ant-menu-item-active {
          color: #000000;
          font-weight: bold;
        }
      }
    }

    :global .ant-menu-submenu-arrow {
      display: none;
    }
  }

  // 尾部
  :global .ant-layout-footer {
    width: 100%;
    background-color: #fff;
  }

  // 去掉 右边框
  :global .ant-menu-inline,
  :global .ant-menu-vertical,
  :global .ant-menu-vertical-left,
  :global .ant-menu-vertical .ant-menu-item::after,
  :global .ant-menu-vertical-left .ant-menu-item::after,
  :global .ant-menu-vertical-right .ant-menu-item::after,
  :global .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }

  :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }

  // 选中/聚焦 时样式覆盖
  :global .ant-menu-submenu-selected,
  :global .ant-menu-submenu-active {
    background-color: #fff;
    color: #000000;
    :global .ant-menu-submenu-title {
      font-size: 14px;
      padding-left: 0px !important;
    }

    :global .ant-menu-item-only-child {
      font-size: 12px;
    }
  }
  // ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open ant-menu-submenu-selected
  //ant-menu-item ant-menu-item-only-child ant-menu-item-selected
  :global .ant-menu-submenu-selected,
  :global .ant-menu-submenu-open {
    :global .ant-menu-submenu-title {
      font-weight: bold;
    }
    :global .ant-menu-item-selected {
      font-weight: bold;
    }
  }
  :global .ant-menu-item:hover,
  :global .ant-menu-item-active,
  :global .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  :global .ant-menu-submenu-active,
  :global .ant-menu-submenu-title:hover,
  :global .ant-menu-item-selected,
  :global .ant-menu-horizontal > .ant-menu-item-selected a,
  :global .ant-menu-horizontal > .ant-menu-item a:hover,
  :global .ant-menu-item-selected a,
  :global .ant-menu-item-selected a:hover,
  :global .ant-menu-item-active a,
  :global .ant-menu-submenu-selected {
    font-weight: bold;
    background-color: #fff;
    color: #000000;
  }
}

:global ul[id*='works'],
:global ul[id*='photoProject'] {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  :global .ant-menu-item a {
    font-weight: normal;
  }

  :global .ant-menu-item a:hover,
  :global .ant-menu-item-selected a,
  :global .ant-menu-item-selected a:hover {
    background-color: #fff;
    color: #000000;
    // font-size: 14px;
    font-weight: bold;
  }

  :global .ant-menu-item {
    padding-left: 24px !important;
  }
}

:global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}

.scroller_layout {
  position: relative;

  .Inf_scroller_sider {
    position: absolute;
  }

  .Inf_scroller_content {
    margin-left: 300px;
    width: 100%;
    //padding-top: 20px;
    overflow: auto;
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;