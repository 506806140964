.bottomNotice {
  z-index: 21;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 40px;
  .bottomNoticeContent {
    border-color: #cfd1d7 !important;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-radius: 2px 2px 0 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    .descIcon {
      font-size: 13px;
      color: #9b9b9b;
      padding-right: 8px;
    }
    .fontBord {
      font-weight: bolder;
    }
    .desc {
      font-size: 12px;
      color: #757575;

    }
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;